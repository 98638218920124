import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { BaseAbstractComponent } from 'src/app/base-abstract/base-abstract.component';
import { HttpApiRequestService } from 'src/app/http/http-api-request.service';
import { DateTimeService } from 'src/app/services/date-time.service';
import { IShare } from 'src/app/types/group-share.model';
import { SharedDetailsService } from '../shared-details.service';
import { UserLoginService } from 'src/app/services/login.service';
import { Role } from 'src/app/types/participant.model';

@Component({
  selector: 'app-expiry-date',
  templateUrl: './expiry-date.component.html',
  styleUrls: ['./expiry-date.component.scss']
})
export class ExpiryDateComponent extends BaseAbstractComponent {

  public shareDetails: IShare;
  public active: boolean;
  public minDate = new Date();
  public maxExpiryDate: Date;
  public date = new FormControl<Date | null>(null);

  constructor(private _dateTimeService: DateTimeService,
    private _loginService: UserLoginService,
    private _httpApiRequestService: HttpApiRequestService,
    private _sharedDetailsService: SharedDetailsService) {
    super();
    this.setupSubscription();
  }

  public onSliderChange(value: boolean) {
    this.active = value;
    // No expiry date
    if (!value) {
      this.date = new FormControl(null);
      let model: any = {
        "isPermanent": true,
        "expirationDate": null
      };
      this.updateShareRequest(model);
    } else {
      this.date = new FormControl(new Date(this._dateTimeService.getExpirationDate()));
      let model: any = {
        "isPermanent": false,
        "expirationDate": this._dateTimeService.getExpirationDate()
      };
      this.updateShareRequest(model);
    }
  }

  // Display tooltip only when slider is disabled
  public getTooltipMessage(): string {
    if (this.disableSlider()) {
      return this.active ? this.translate.instant('Settings.Expiry_date_accordian.Slider_yes_tooltip') : this.translate.instant('Settings.Expiry_date_accordian.Slider_no_tooltip');
    }
    return "";
  }

  // Only Admin has access to slider
  public disableSlider(): boolean {
    return !this._loginService.isLoggedInUserIsAdmin();
  }

  // Calender is enable when logged in user is Admin/owner
  public disableCalender(): boolean {
    if (this._loginService.isLoggedInUserIsAdmin() || this.isCurrentUserAnOwnerOfAShare) {
      return !this.active;
    }
    return true;
  }

  public addEvent(event: MatDatepickerInputEvent<Date>) {
    // capture date from date picker and apply required format
    this.shareDetails.expirationDate = this._dateTimeService.formatDate(event.value);
    let model: any = {
      "isPermanent": false,
      "expirationDate": this.shareDetails.expirationDate
    };
    this.updateShareRequest(model);
  }

  private setupSubscription() {
    const showDetails = this._sharedDetailsService.onSharedDetailsUpdated({
      next: (x: IShare) => {
        this.shareDetails = x;
        this.active = !x.isPermanent;
        this.setExpiryDate();
        this.setMaxExpiryDate()
      },
      error: (error) => { this.handleError(error) },
      complete() { },
    });
    this.subscriptions.push(showDetails);
  }

  private setExpiryDate() {
    this.date = this.shareDetails.isPermanent ? new FormControl(null) : new FormControl(new Date(this.shareDetails.expirationDate));
  }

  private setMaxExpiryDate() {
    this.maxExpiryDate = new Date();
    this.maxExpiryDate.setDate(this._dateTimeService.readDateFromString(this.shareDetails.creationDateTime).getDate() + 365);
  }

  private isCurrentUserAnOwnerOfAShare(value: IShare): boolean {
    return value.rightTypeOfCurrentUser === Role.OWNER;
  }

  private updateShareRequest(model: any) {
    const updateShareRequestSub = this._httpApiRequestService.updateShareRequest(model, this.shareDetails.id).subscribe({
      next: (result: IShare) => {
        this._sharedDetailsService.updateSharedDetails(result);
       },
      error: (error) => { this.handleError(error) },
      complete() { },
    });
    this.subscriptions.push(updateShareRequestSub);
  }
}
