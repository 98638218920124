import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime, fromEvent, map } from 'rxjs';
import { BaseAbstractComponent } from 'src/app/base-abstract/base-abstract.component';
import { SnackBarService } from 'src/app/common-ui/snack-bar/service/snack-bar.service';
import { DownloadFileDialogComponent } from 'src/app/dialogs/download-file-dialog/download-file-dialog.component';
import { ReplaceFileDialogComponent } from 'src/app/dialogs/replace-file-dialog/replace-file-dialog.component';
import { HttpApiRequestService } from 'src/app/http/http-api-request.service';
import { ShareService } from 'src/app/myshare/services/share.service';
import { FileFactoryService } from 'src/app/services/FileOperationFactory/file-factory.service';
import { FileManager, UploadStatus } from 'src/app/services/FileOperationFactory/file-manager.model';
import { FileSizeManagerService } from 'src/app/services/file-size-manager.service';
import { UserLoginService } from 'src/app/services/login.service';
import { DialogModel, FileDialogModel } from 'src/app/types/dialog-model';
import { IFile, IFileStatus, RestrictionType } from 'src/app/types/file-model';
import { IShare } from 'src/app/types/group-share.model';
import { Role } from 'src/app/types/participant.model';
import { UserRole } from 'src/app/types/user-roles.model';
import { ArrayOperationsUtility } from 'src/app/utilities/arrayOperations.utility';
import { CompareUtility } from 'src/app/utilities/compare.utility';
import { DeleteDialogComponent } from '../../dialogs/delete-dialog/delete-dialog.component';
import { SharedDetailsService } from '../shared-details.service';
import { TabManagerService, Tabs } from 'src/app/services/tab-manager.service';

@Component({
  selector: 'app-shared-files',
  templateUrl: './shared-files.component.html',
  styleUrls: ['./shared-files.component.scss']
})
export class SharedFilesComponent extends BaseAbstractComponent implements AfterViewInit {

  @ViewChild(MatSort) sort: MatSort
  @ViewChild('search') filterElementRef: ElementRef;
  private _fileFactoryService: FileFactoryService;

  displayedColumns = ['safetyStatus', 'fileName', 'creationDateTime', 'fileSizeMB', 'options'];
  dataSource = new MatTableDataSource<IFile>([]);
  files: IFile[] = [];
  showParticipantButton: boolean;
  shareDetails: IShare;
  displayError: string;
  searchKey: string = '';
  restrictionString: string;

  constructor(private _sharedDetailsService: SharedDetailsService,
    private _fileSizeManagerService: FileSizeManagerService,
    private _httpApiRequestService: HttpApiRequestService,
    private _tabManagerService: TabManagerService,
    private _shareService: ShareService,
    private _loginService: UserLoginService,
    private _snackBarService: SnackBarService) {
    super();
  }

  ngAfterViewInit() {
    this.setupSubscription();

    const keyup$ = fromEvent(this.filterElementRef.nativeElement, 'keyup');
    keyup$
      .pipe(
        map((i: any) => i.currentTarget.value),
        debounceTime(500)
      )
      .subscribe({
        next: (result: any) => {
          this.searchKey = result.trim();
          console.log(this.searchKey);
          this.loadSharedFiles();
        },
        error: (error) => { this.handleError(error); },
        complete: () => { }
      }
      );
  }

  private addOrReplaceFiles(files: IFile[]) {
    if (this.dataSource.data.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const element = files[i];
        const replaceFileIndex = this.dataSource.data.findIndex(x => x.fileName === element.fileName);
        if (replaceFileIndex >= 0) {
          this.dataSource.data[replaceFileIndex] = element;
        } else {
          this.dataSource.data.push(element);
        }
      }
    }

  }

  loadSharedFiles() {
    this._httpApiRequestService.loadSharedFiles(this.shareDetails.id, this.searchKey).subscribe({
      next: (result: IFile[]) => {
        this.dataSource.data = this.cloneFiles(result);
        if (this._shareService.isFileFactoryExist(this.shareDetails.id)) {
          let factory = this._shareService.getFileFactory(this.shareDetails.id);
          this.addOrReplaceFiles(factory.getUploadingFiles());
        }
        this.dataSource.sort = this.sort;
        if (this._sharedDetailsService.fileToUpload?.length > 0) {
          this.files = this._sharedDetailsService.fileToUpload;
          this._sharedDetailsService.fileToUpload = [];
          this.uploadFiles();
        } else {
          this._sharedDetailsService.updateSharedFiles(result);
          this.initializeFileSizeValues(result);
        }
      },
      error: (error) => { this.handleError(error); },
      complete: () => { }
    });
  }

  downloadAllFiles() {
    let snackbarData = this.translate.instant('Common.Downloading');
    this._snackBarService.openSnackBar(this.shareDetails.id, snackbarData.concat(' ', this.shareDetails.shareName, '.zip'));
    this._httpApiRequestService.dowloadAllFilesOfShare(this.shareDetails.id).subscribe({
      next: (result: any) => {
        let hyperlink = document.createElement('a');
        hyperlink.href = window.URL.createObjectURL(result as Blob);
        hyperlink.download = this.shareDetails.shareName.concat('.zip');
        hyperlink.click();
        this._snackBarService.closeSnackBarRequest(this.shareDetails.id);
      },
      error: (error) => {
        this.handleError(error);
        this._snackBarService.closeSnackBarRequest(this.shareDetails.id);
      },
      complete: () => { }
    });
  }

  // Used in file-size component to validate user input
  private initializeFileSizeValues(result: IFile[]) {
    if (CompareUtility.isDefinedAndNotNull(result) && result.length > 0) {
      let sizeArray = result.map(x => this._fileSizeManagerService.formatFileSize(x.fileSizeMB));
      this._sharedDetailsService.updateMaxFileSizeInBytes(this.getMaxSizeUploaded(sizeArray)
      );
    } else {
      this._sharedDetailsService.updateMaxFileSizeInBytes(0);
    }
  }

  setupSubscription() {
    const showShareDetails = this._sharedDetailsService.onSharedDetailsUpdated({
      next: (x) => {
        this.shareDetails = x;
        this._fileFactoryService = this._shareService.getFileFactory(x.id);
        this.setupFileFactorySubscription();
        this.loadSharedFiles();
        this.createRestrictionString();
      },
      error: (error) => { this.handleError(error) },
      complete() { }
    });
    this.subscriptions.push(showShareDetails);

    const tabChangedSubscritpion = this._sharedDetailsService.onTabChanged({
      next: (x: number) => {
        this.displayError = '';
      },
      error: (error) => { this.handleError(error) },
      complete() { }
    });
    this.subscriptions.push(tabChangedSubscritpion);
  }

  private setupFileFactorySubscription() {
    const uploadInitiated = this._fileFactoryService.onUploadInitiated({
      next: (files: IFile[]) => {
        this.dataSource.data = this.dataSource.data.concat(files);
        this._shareService.storeFileFactory(this.shareDetails.id, this._fileFactoryService);
      },
      error: (error) => { this.handleError(error) },
      complete() { }
    });
    this.subscriptions.push(uploadInitiated);

    const uploadFinished = this._fileFactoryService.onUploadFinish({
      next: (status: boolean) => {
        this.loadSharedFiles();
        this._httpApiRequestService.refreshActionLogs(this.shareDetails.id);
        this.files = [];
      },
      error: err => {
      },
      complete() { }
    });
    this.subscriptions.push(uploadFinished);

    const compressedFolderFinish = this._fileFactoryService.compressFolderFinish$.subscribe({
        next: (res) => {
          this.files.push(res);
          if (this.compressingFinished()) {
            this.addFiles();
          }
        },
      });
    this.subscriptions.push(compressedFolderFinish);
  }

  private getSizeUploaded() {
    let totalSize: number = 0;
    this.files.forEach((x) => {
      totalSize = totalSize + x.size;
    });
    return totalSize;
  }

  public validateFiles(): boolean {
    return this._fileSizeManagerService.isFileSizeLessThanGB(this.getSizeUploaded());
  }

  private addFiles() {
    this.displayError = '';

    // validate if share size exeeds 1 GB
    if (!this.validateFiles()) {
      this.translate.get('ErrorMessages.FileLimitError').subscribe((x: string) =>
        this.showDisplayError(x));
      this.files = [];
      return;
    }

    // validate file type
    if (this.shareDetails.restrictedFileTypes.length > 0) {
      let extensions: string[] = this.getFilesExtensions().map(x => x.toUpperCase());
      let restrictedFileTypes: string[] = this.shareDetails.restrictedFileTypes.map(x => x.fileType);
      let restrictionType: string = this.shareDetails.restrictedFileTypes[0].restrictionType;
      if (restrictionType == RestrictionType.DENY.toString()) {
        if (ArrayOperationsUtility.isArray1IncludesArray2(restrictedFileTypes, extensions)) {
          this.translate.get('Files.ErrorMessages.OnUserAddingDenyRestrictedExtensions', { restrictedExtensions: restrictedFileTypes.join(', ') }).subscribe((x: string) =>
            this.showDisplayError(x));
          this.files = [];
          return;
        }
      }
      if (restrictionType == RestrictionType.ALLOW.toString()) {
        if (!ArrayOperationsUtility.isArray1IncludesArray2(restrictedFileTypes, extensions)) {
          this.translate.get('Files.ErrorMessages.OnUserAddingAllowRestrictedExtensions', { restrictedExtensions: restrictedFileTypes.join(', ') }).subscribe((x: string) =>
            this.showDisplayError(x));
          this.files = [];
          return;
        }
      }
    }

    // validate file size
    if (CompareUtility.isDefinedAndNotNull(this.shareDetails.maxFileSizeMB) && this.shareDetails.maxFileSizeMB > 0) {
      let sizeArray: number[] = this.files.map(x => x.size);
      let isError = this._fileSizeManagerService.isFileSizeLessThanLimit(this.getMaxSizeUploaded(sizeArray), this.shareDetails.maxFileSizeMB);
      if (isError) {
        this.translate.get('Files.ErrorMessages.ShareExceedsAllowedFileSizeLimit', { limit: this.shareDetails.maxFileSizeMB }).subscribe((x: string) =>
        this.showDisplayError(x));
        return;
      }
    }

    // replace file popup
    let filesAlreadyExists = this.getCommonFileNames();
    if (filesAlreadyExists.length > 0) {
      this.openReplaceFileDialog(filesAlreadyExists);
    } else {
      this.uploadFiles();
    }
  }

  private removeFilesFromDatasource(files: IFile[]) {
    files.forEach(file => {
      let index = this.dataSource.data.findIndex(data => data.fileName === file.fileName);
      if (index >= 0) {
        this.dataSource.data.splice(index, 1);
      }
    });
  }

  private openReplaceFileDialog(files: IFile[]) {
    let data = new FileDialogModel();
    this.translate.get('Files.ReplaceFileDialog.Title').subscribe((x: string) => data.title = x);
    this.translate.get('Files.ReplaceFileDialog.Message').subscribe((x: string) => data.content = x);
    data.fileNames = files.map(x => x.fileName);
    const dialogRef = this.dialog.open(ReplaceFileDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: data,
      height: '280px',
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.removeFilesFromDatasource(files);
        this.uploadFiles();
      } else {
        this.files = [];
      }
    });
  }

  private getCommonFileNames(): IFile[] {
    let files: IFile[] = [];
    this.dataSource.data.forEach(x => {
      let commonFiles: IFile[] = this.files.filter(y => y.fileName === x.fileName);
      if (CompareUtility.isDefinedAndNotNull(commonFiles) && commonFiles.length > 0) {
        files = files.concat(commonFiles);
      }
    });
    return files;
  }

  private getFilesExtensions(): string[] {
    let filenames = this.files.map(x => x.fileName.split('.').pop());
    let extensions: string[] = ArrayOperationsUtility.removeDuplicates(filenames);
    return extensions;
  }

  private showDisplayError(error: string) {
    this.displayError = error;
    setTimeout(() => {
      this.displayError = '';
    }, 10000);
  }

  private getMaxSizeUploaded(files: number[]) {
    return Math.max(...files);
  }

  onDownloadClicked(file: IFile) {
    if (file.status == IFileStatus.SCANNED) {
      this.downloadFile(file);
    } else {
      this.openDownloadFileDialog([file], false);
    }
  }

  onDownloadAllFilesClicked() {
    let suspiciousFiles = this.dataSource.data.filter(x => x.status == IFileStatus.CREATED);
    if (suspiciousFiles.length > 0) {
      this.openDownloadFileDialog(suspiciousFiles, true);
    } else {
      this.downloadAllFiles();
    }
  }

  disableDownloadButton() {
    return this.dataSource.data?.length === 0 || this._fileFactoryService?.isFileUploadInProgress;
  }

  disableAddFilesButton() {
    return this.isCurrentUserAReaderOfAShare() || this._fileFactoryService?.isFileUploadInProgress;
  }

  openDialog(file: IFile): void {
    let data = new DialogModel();
    this.translate.get('Dialogs.DeleteFileDialog.Title').subscribe((x: string) => data.title = x);
    this.translate.get('Dialogs.DeleteFileDialog.Message').subscribe((x: string) => data.content = x);
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: data,
      height: '225px',
      width: '450px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.deleteFile(file.id);
      }
    });
  }

  formatFileSize(fileSizeMB: number): number {
    return this._fileSizeManagerService.formatFileSize(fileSizeMB);
  }

  navigateToParticipantTab() {
    this.showParticipantButton = false;
    this._tabManagerService.updateTab(Tabs.ParticipantTab);
  }

  isAddWorkflow(): boolean {
    return this._sharedDetailsService.isAddWorkflow;
  }

  isCurrentUserAReaderOfAShare(): boolean {
    return this.shareDetails?.rightTypeOfCurrentUser === Role.READER && !this._loginService.isLoggedInUserIsAdmin();
  }

  public getProgress(file: IFile): boolean {
    let status = file.uploadStatus$.getValue();
    return (status === UploadStatus.INPROGRESS) ||
      (status === UploadStatus.START) || (status === UploadStatus.FAILED) || (status === UploadStatus.PAUSE);
  }

  public isUploadFailed(file: IFile): boolean {
    let status = file.uploadStatus$.getValue();
    return status === UploadStatus.FAILED;
  }

  public resumeFileUpload(file: IFile): void {
    file.resumeUpload();
  }

  public isUserExternalPerson() {
    return this._loginService.isLoggedInUserIsExternalPerson();
  }

  private openDownloadFileDialog(files: IFile[], downloadAllFiles: boolean) {
    let data = new FileDialogModel();
    this.translate.get('Files.DownloadFileDialog.Title').subscribe((x: string) => data.title = x);
    this.translate.get('Files.DownloadFileDialog.Message').subscribe((x: string) => data.content = x);
    data.fileNames = files.filter(x => x.status === IFileStatus.CREATED).map(x => x.fileName);
    const dialogRef = this.dialog.open(DownloadFileDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: data,
      height: '280px',
      width: '500px',
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        if(downloadAllFiles) {
          this.downloadAllFiles();
        } else {
          this.downloadFile(files[0]);
        }
      }
    });
  }

  private uploadFiles() {
    this._fileFactoryService.initiateUpload(this.files, this.shareDetails.id);
  }

  private deleteFile(fileId: string) {
    this._httpApiRequestService.deleteFile(this.shareDetails.id, fileId).subscribe({
        next: (result: any) => {
          this.loadSharedFiles();
          this._httpApiRequestService.refreshShare(this.shareDetails.id);
          this._httpApiRequestService.refreshActionLogs(this.shareDetails.id);
        },
        error: error => {},
        complete: () => { }
      });
  }

  private downloadFile(file: IFile) {
    let snackbarData = this.translate.instant('Common.Downloading');
    this._snackBarService.openSnackBar(this.shareDetails.id, snackbarData.concat(' ', file.fileName));
    this._httpApiRequestService.downloadFile(this.shareDetails.id, file.id).subscribe({
      next: (result: any) => {
        // Create hyperlink to download the file as per browser download setting
        let hyperlink = document.createElement('a');
        hyperlink.href = window.URL.createObjectURL(result as Blob);
        hyperlink.download = file.fileName;
        hyperlink.click();
        this._httpApiRequestService.refreshActionLogs(this.shareDetails.id);
        this._snackBarService.closeSnackBarRequest(this.shareDetails.id);
      },
      error: (error) => {
        this.handleError(error);
        this._snackBarService.closeSnackBarRequest(this.shareDetails.id);
      },
      complete: () => { }
    });
  }

  /* Create file manager for each file*/
  private cloneFiles(files: IFile[]) : IFile[] {
    let managers: IFile[] = [];
    files.forEach(file => {
      let manager = new FileManager();
      manager.fileName = file.fileName;
      manager.creationDateTime = file.creationDateTime;
      manager.id = file.id;
      manager.fileSizeMB = file.fileSizeMB;
      manager.size = file.size;
      manager.uniqueIdentifier = file.uniqueIdentifier;
      manager.status = file.status;
      managers.push(manager);
    });
    return managers;
  }

  public fileInFolder(plainPath: string): boolean {
    return plainPath.split('/').length > 1;
  }

  compressingFinished() {
    const status = this._fileFactoryService.folders.every(
      (folder) => folder.compressingFinished === true
    );
    return status;
  }

  public isUploadInProgress(file: IFile): boolean {
    let status = file.uploadStatus$.getValue();
    return status === UploadStatus.INPROGRESS;
  }

  public pauseFileUpload(file: IFile): void {
    file.pauseUpload();
  }

  public onFileSelected(files: File[]): void {
    for (let i = 0; i < files.length; i++) {
      this.files.push(this._fileFactoryService.getFileManagerFromFile(files[i]));
    }
    this.addFiles();
  }

  public onFolderSelected(files: File[]): void {
    for (let i = 0; i < files.length; i++) {
      this._fileFactoryService.createOrUpdateFolder(files[i]);
    }
    this._fileFactoryService.compressFolder();
  }

  public checkStatusReturnImgSource(file: IFile): string {
    let srcPath = '';
    switch(file.status) {
      case IFileStatus.CREATED:
        srcPath = 'assets/images/fileNotScanned.png';
        break;
      case IFileStatus.INFECTED:
        srcPath = 'assets/images/fileUnsafe.png';
        break;
      case IFileStatus.SCANNED:
        srcPath = 'assets/images/fileSafe.png';
        break;
      default:
        srcPath = 'assets/images/fileNotScanned.png';
        break;
    }
    return srcPath;
  }

  public checkStatusReturnLabel(file: IFile): string {
    let label = '';
    switch(file.status) {
      case IFileStatus.CREATED:
        this.translate.get('Files.FileCreated').subscribe((x:string) => label = x);
        break;
      case IFileStatus.INFECTED:
        this.translate.get('Files.FileUnsafe').subscribe((x:string) => label = x);
        break;
      case IFileStatus.SCANNED:
        this.translate.get('Files.FileSafe').subscribe((x:string) => label = x);
        break;
      default:
        this.translate.get('Files.FileCreated').subscribe((x:string) => label = x);
        break;
    }
    return label;
  }

  isInfectedFile(file: IFile): boolean {
    return file.status === IFileStatus.INFECTED;
  }

  showOptions(): boolean {
    return !((this._loginService.loggedInUserRole() === UserRole.GUESTUSER) || (this._loginService.loggedInUserRole() === UserRole.EXTERNAL_USER));
  }

  onAddFileButtonClicked() {
    this.displayError = '';
  }

  private createRestrictionString() {
    if(this.shareDetails.restrictedFileTypes.length > 0) {
      this.shareDetails.restrictedFileTypes.map(result => result.fileType)
      let fileTypes = this.shareDetails.restrictedFileTypes.map(result => result.fileType);
      this.restrictionString = fileTypes.join(', ')
    }
  }
}
