import { NotificationAbstractRequest, requestType } from "src/app/http/request-urls/requestUrl.model";
import { TokenExchangeService } from "src/app/services/token-exchange.service";

export class UpdateNotificationPreferences extends NotificationAbstractRequest {
    constructor(private _shareId: string, private _data: any, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Put;
        this.body = this._data;
        this.options = {
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat('/notification-preferences/' + this._shareId);
    }
}

export class GetNotificationPreferences extends NotificationAbstractRequest {
    constructor(private _shareId: string, private _userId: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Get;
        this.options = {
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat('/notification-preferences/' + this._shareId + '/' + this._userId);
    }
}

export class DeleteNotificationPreferencesRequest extends NotificationAbstractRequest {
    constructor(private _shareId: string, private _participantId: string, private _workRelationId: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Delete;
        this.options = {
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat("/notification-preferences/" + this._shareId + "/" + this._participantId + "/" + this._workRelationId);
    }
}
