import { ParticipantAbstractRequest, requestType } from "src/app/http/request-urls/requestUrl.model";
import { TokenExchangeService } from "src/app/services/token-exchange.service";
import { ContactType } from "../../types/participant.model";

export class SearchOrganizationsRequest extends ParticipantAbstractRequest {

    constructor(private organisationName: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Get;
        const params = { 'organisationName': this.organisationName };
        this.options = {
            params: params,
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat('/contacts/organisations');
    }
}

export class AddAdditionalContactRequest extends ParticipantAbstractRequest {

    constructor(private _data: Object, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Post;
        this.body = this._data;
        this.options = {
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat('/additional-contact');
    }
}


export class DeleteAdditionalContactRequest extends ParticipantAbstractRequest {

    constructor(private _participantId: string, private _workRelationId: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Delete;
        this.options = {
            headers: this.headers,
            responseType: 'text'
        }
        this.url = this.url.concat('/additional-contact/', this._participantId, '/', this._workRelationId);
    }
}

export class SearchContactsRequest extends ParticipantAbstractRequest {

    constructor(public tokenExchangeService: TokenExchangeService, private searchKey: string, private entityName: string) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Get;
        const params = { 'displayName': this.searchKey, 'email': this.searchKey, 'entityName': this.entityName, 'type': 'USER', 'active': true };
        this.options = {
            params: params,
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat('/contacts');
    }
}

export class SearchParticipantRequest extends ParticipantAbstractRequest {
    constructor(private entityId: string, private _searchfield: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Get;
        this.options = {
            params: { 'entityId': this.entityId,'displayName': this._searchfield, 'email': this._searchfield, 'entityNameOfParticipant': this._searchfield, 'active': true },
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat('/contacts');
    }
}

export class AddParticipantRequest extends ParticipantAbstractRequest {
    constructor(private _data: any, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Post;
        this.options = {
            headers: this.headers,
            responseType: 'json'
        }
        this.body = this._data;
        this.url = this.url.concat('/participants');

    }
}

export class UpdateParticipantRequest extends ParticipantAbstractRequest {
    constructor(private _participantid: string, private _data: any, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Put;
        this.options = {
            headers: this.headers,
            responseType: 'json'
        }
        this.body = this._data;
        this.url = this.url.concat('/participants/', this._participantid);

    }
}

export class GetSystemDetailRequest extends ParticipantAbstractRequest {
    constructor(private _participantid: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Get;
        this.options = {
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat('/participants/', this._participantid);

    }
}

export class GetContactRequest extends ParticipantAbstractRequest {

    constructor(public tokenExchangeService: TokenExchangeService, private participantId: string, private workRelationId: string) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Get;
        const params = {};
        this.options = {
            params: params,
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat('/additional-contact/participant/', this.participantId, '/workRelation/', this.workRelationId);
    }
}


export class GetContactsRequest extends ParticipantAbstractRequest {

    constructor(public tokenExchangeService: TokenExchangeService, private pageNumber: number, private filter: string, private entityId: string, private contactType: ContactType){
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Get;
        const params = {'entityId': this.entityId, 'displayName': this.filter, 'email': this.filter, 'entityNameOfParticipant': this.filter, 'page': this.pageNumber, 'maxPageSize': 20, 'sort': 'displayName', 'sortDirection': 'ASC', 'type': this.contactType};
        this.options = {
            params: params,
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat('/contacts');
    }
}

export class DeleteParticipantRequest extends ParticipantAbstractRequest {

    constructor(private _participantId: string, private _workRelationId:string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Delete;
        this.options = {
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat('/participants/', this._participantId, '/workRelationId/', this._workRelationId);
    }
}

export class GetMeRequest extends ParticipantAbstractRequest {
    constructor(public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Get;
        this.options = {
            params: { 'externalPersonToken': this.tokenExchangeService.getInviteTokenParam() },
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat('/me');
    }
}