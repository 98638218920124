import { Component, ElementRef, ViewChild } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from '../../environments/environment';
import { BaseAbstractComponent } from '../base-abstract/base-abstract.component';
import { UserLoginService } from '../services/login.service';
import { BrowserOperationService } from '../services/browser-operation.service';
import { TokenManagerService } from '../services/token-manager.service';
import { SelectOptions } from '../common-ui/single-select/model/single-select.model';
import { ResizedEvent } from 'angular-resize-event';
import { UserRole } from '../types/user-roles.model';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent extends BaseAbstractComponent {

  isAuthenticated = false;
  helloText: string = 'Hello';
  languages: SelectOptions[] = [];
  selectedLanguage: string;
  name: string = '';
  entityNameOfParticipant: string = '';
  entityId: string = '';
  adminRoles: SelectOptions[] = [];
  selectedRole: string;
  canUserChangeRole: boolean;
  access: boolean;

  @ViewChild('userdetailscontainer') userdetailscontainer: ElementRef;
  @ViewChild('hellocontainer') hellocontainer: ElementRef;
  @ViewChild('entitycontainer') entitycontainer: ElementRef;

  constructor(public oidcSecurityService: OidcSecurityService,
    private _loginService: UserLoginService,
    private _tokenManagerService: TokenManagerService,
    private _browserOperationService: BrowserOperationService) {
    super();
    this.setupSubscription();
    this.setDefaultLanguage();
    this.initializeLanguages();
  }

  public signOut(): void {
    if (this._browserOperationService.preventBroswserOperations()) {
      this._browserOperationService.openDialog();
      return;
    }
    this._loginService.logout();
  }

  public signIn(): void {
    this.oidcSecurityService.authorize();
  }

  public onLanguageChanged(e: string) {
    this.translate.use(environment.locale.find(x => x.name == e).code);
  }

  public isExternalPerson() {
    return this._loginService.isLoggedInUserIsExternalPerson();
  }

  private setDefaultLanguage() {
    let defaultLang = this.findBrowserLanguage();
    this.translate.setDefaultLang(defaultLang);
    this.translate.use(defaultLang);
    this.selectedLanguage = environment.locale.find(x => x.code == defaultLang).name;
  }

  private setupSubscription() {
    const initiateLandingPage = this._loginService.initiateToolbar({
      next: (value) => {
        if (value) {
          this.name = this._loginService.userName;
          this.entityNameOfParticipant = this._loginService.entityNameOfParticipant;
          this.entityId = this._loginService.entityId;
          this.canUserChangeRole = this._loginService.canUserBeAdmin();
          this.access = this._loginService.access;
          this.initialiseRoles();
        }
      },
      error: (error) => { this.handleError(error) },
      complete() { },
    });
    this.subscriptions.push(initiateLandingPage);

    // signout event
    const isAuthenticated = this.oidcSecurityService.isAuthenticated$.subscribe(({ isAuthenticated }) => {
      this.isAuthenticated = isAuthenticated;
      if (!isAuthenticated && this._tokenManagerService.isAccessTokenExpired()) {
        this.oidcSecurityService.authorize();
        console.log('this.oidcSecurityService.authorize() is called from toolbar component');
      }
      else this.isAuthenticated = true;
    });
    this.subscriptions.push(isAuthenticated);
  }

  private initialiseRoles() {
    if (this._loginService.loggedInUserRole() === UserRole.ADMIN) {
      this.initializeAdminRole();
    } else if (this._loginService.loggedInUserRole() === UserRole.ADMIN_AS_USER) {
      this.initializeAdminUserRole();
    } else if (this._loginService.loggedInUserRole() === UserRole.USER) {
      this.initializeUserRole();
    } else if (this._loginService.loggedInUserRole() === UserRole.GUESTUSER) {
      this.initializeGuestUserRole();
    } else if (this._loginService.loggedInUserRole() === UserRole.EXTERNAL_USER) {
      this.initializeExternalUserRole();
    }
  }

  private initializeAdminRole() {
    this.adminRoles.push({ title: 'Common.Admin', value: UserRole.ADMIN, isTitleTranslated: false });
    this.adminRoles.push({ title: 'Common.User', value: UserRole.ADMIN_AS_USER, isTitleTranslated: false });
    this.selectedRole = UserRole.ADMIN;
  }

  private initializeAdminUserRole() {
    this.adminRoles.push({ title: 'Common.Admin', value: UserRole.ADMIN, isTitleTranslated: false });
    this.adminRoles.push({ title: 'Common.User', value: UserRole.ADMIN_AS_USER, isTitleTranslated: false });
    this.selectedRole = UserRole.ADMIN_AS_USER;
  }

  private initializeUserRole() {
    this.adminRoles.push({ title: 'Common.User', value: UserRole.USER, isTitleTranslated: false });
    this.selectedRole = UserRole.USER;
  }

  private initializeGuestUserRole() {
    this.adminRoles.push({ title: 'Common.GuestUser', value: UserRole.GUESTUSER, isTitleTranslated: false });
    this.selectedRole = UserRole.GUESTUSER;
  }

  private initializeExternalUserRole() {
    this.adminRoles.push({ title: 'Common.ExternalUser', value: UserRole.EXTERNAL_USER, isTitleTranslated: false });
    this.selectedRole = UserRole.EXTERNAL_USER;
  }

  private initializeLanguages() {
    environment.locale.map(x => x.name).forEach(x => {
      this.languages.push({ title: x, value: x });
    });
  }

  private findBrowserLanguage(): string {
    let navLanguage = navigator.language.split('-')[0];
    return environment.locale.find(x => x.code == navLanguage) ? navLanguage : environment.defaultLocale;
  }

  public onAdminRoleChanged(e: string) {
    this._loginService.changeRole(<UserRole>e);
  }

  onResized(event: ResizedEvent) {
    this.changeFlexDirection();
  }

  public changeFlexDirection() {
    return (this.hellocontainer?.nativeElement?.offsetWidth + this.entitycontainer?.nativeElement?.offsetWidth + 50) > this.userdetailscontainer?.nativeElement?.offsetWidth;
  }

  public changeFlexDirectionUsername() {
    return (this.hellocontainer?.nativeElement?.offsetWidth + 50 > this.userdetailscontainer?.nativeElement?.offsetWidth);
  }
}

