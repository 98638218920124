import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observer, Subject, Subscription } from 'rxjs';
import { RenameFileDialogComponent } from 'src/app/dialogs/rename-file-dialog/rename-file-dialog.component';
import { FileDialogModel } from 'src/app/types/dialog-model';
import { IFile } from 'src/app/types/file-model';
import { ServiceLocator } from 'src/app/types/service-locator';

/** Instance is injected on transfer component or it's parent component */
@Injectable()
export class TransferService {

  private _selectedFiles$: Subject<IFile[]>;
  private _clearTransfer$: Subject<boolean>;
  private fileNames: { fileName: string, val: number }[] = [];
  private dialog: MatDialog;
  private files: IFile[] = [];
  private regex = /\(\d+\)/;

  constructor(private translate: TranslateService) {
    this.dialog = ServiceLocator.injector.get(MatDialog);
    this._selectedFiles$ = new Subject<IFile[]>();
    this._clearTransfer$ = new Subject<boolean>();
  }

  public updateFiles(filesToSend: IFile[]) {
    let existingFiles: IFile[] = [];
    filesToSend.forEach(file => {
      let nameSplit = this.createNameSplit(file.fileName);
      if (this.nameExist(nameSplit)) {
        existingFiles.push(file)
      } else {
        this.files.push(file);
        this.updateFileNames(nameSplit);
      }
    })
    if (existingFiles.length > 0) {
      this.openRenameFileDialog(existingFiles);
    } else {
      this._selectedFiles$.next(this.files);
    }
  }

  public onFilesSelected(observer: Observer<IFile[]>): Subscription {
    return this._selectedFiles$.subscribe(observer);
  }

  public clearTransfer() {
    this.files = [];
    this.fileNames = [];
    this._clearTransfer$.next(true);
  }

  public onClearTransfer(observer: Observer<boolean>): Subscription {
    return this._clearTransfer$.subscribe(observer);
  }

  public removeFile(file: IFile) {
    let index = this.files.findIndex(x => x.uniqueIdentifier === file.uniqueIdentifier);
    this.files.splice(index, 1);
    this._selectedFiles$.next(this.files)
    this.removeFileName(file.fileName)
  }


  private openRenameFileDialog(files: IFile[]) {
    let data = new FileDialogModel();
    this.translate.get('Files.RenameFileDialog.Title').subscribe((x: string) => data.title = x);
    this.translate.get('Files.RenameFileDialog.Message').subscribe((x: string) => data.content = x);
    data.fileNames = files.map(x => x.fileName);
    const dialogRef = this.dialog.open(RenameFileDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: data,
      height: '280px',
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        files.forEach(file => {
          this.files.push(this.renameFile(file));
        })
      }
      this._selectedFiles$.next(this.files);
    });
  }

  private nameExist(fileName: string): boolean {
    return this.fileNames.some(x => x.fileName == fileName);
  }

  private updateFileNames(newFileName: string) {
    if (this.fileNames.some(x => newFileName == x.fileName)) {
      this.fileNames.find(x => newFileName == x.fileName).val += 1;
    } else {
      this.fileNames.push({ fileName: newFileName, val: 1 });
    }
  }

  private renameFile(file: IFile): IFile {
    let fileExt = this.readExtension(file.fileName);
    let nameSplit = this.createNameSplit(file.fileName);
    this.updateFileNames(nameSplit);
    nameSplit = nameSplit.concat('(' + (this.fileNames.find(x => x.fileName == nameSplit).val - 1) + ')')
    file.fileName = nameSplit + '.' + fileExt;
    return file;
  }

  private removeFileName(fileName: string) {
    let nameSplit = this.createNameSplit(fileName);
    let index = this.fileNames.findIndex(x => nameSplit == x.fileName);
    if (this.fileNames[index]?.val > 1) {
      this.fileNames[index].val -= 1;
    } else {
      this.fileNames.splice(index, 1);
    }
  }

  private createNameSplit(fileName: string) {
      let nameArray = fileName.split('.');
      nameArray.pop();
      return nameArray.join('.').replace(this.regex, '');
  }

  private readExtension(fileName: string) {
    let nameArray = fileName.split('.');
    return nameArray.pop();
  }
}
