<main class="outer-container">
  <div class="title-container">
    <img  src="assets/images/activeFolder.png" alt="{{'Images.ActiveFolderIcon' | translate}}"
      class="myShareImg">{{'AllShare.Title' | translate}}
  </div>
  <div class="searchContainer">
    <input #search type="text" name="searchKey" [(ngModel)]="searchKey" autocomplete="off" maxlength="25" placeholder="{{'MyShare.Search_bar' | translate}}"/>
  </div>
  <section class="shares-container mat-elevation-z8" infinite-scroll [scrollWindow]="false" (scrolled)="onScrollDown($event)">
    <table mat-table [dataSource]="dataSource" matSort>

      <!-- Name Column -->
      <ng-container matColumnDef="shareName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'MyShare.Name' | translate }}</th>
        <td mat-cell appThrottleClick (throttleClick)="openShare(element)" *matCellDef="let element" class="pointer">
          <div textOverflow [textValue]="element.shareName">{{element.shareName}}</div>
        </td>
      </ng-container>

      <!-- Creation Date Column -->
      <ng-container matColumnDef="creationDateTime" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'MyShare.Creation_Date' | translate}}</th>
        <td mat-cell appThrottleClick *matCellDef="let element" class="pointer" (throttleClick)="openShare(element)"> {{element.creationDateTime
          | date : dateFormat : undefined : locale }} </td>
      </ng-container>

      <!-- Expiration Date Column -->
      <ng-container matColumnDef="expirationDate" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'MyShare.Expiration_Date'| translate}}</th>
        <td mat-cell appThrottleClick *matCellDef="let element" class="pointer" (throttleClick)="openShare(element)"> 
          <span *ngIf="!element.isPermanent; else none_content">
            {{element.expirationDate | date : dateFormat : undefined : locale}}
          </span>
          <ng-template #none_content>{{'Common.None'| translate}}</ng-template>
        </td>
      </ng-container>

      <!-- Participants Column -->
      <ng-container matColumnDef="participants">
        <th mat-header-cell *matHeaderCellDef>{{'MyShare.Participants'| translate}}</th>
        <td mat-cell *matCellDef="let element">
          <span class="iconContainer" [matMenuTriggerFor]="participantMenu" (click)="getParticipantNames(element)">
            <img src="assets/images/groupShareIcon.png">
          </span>
          <mat-menu #participantMenu="matMenu" xPosition="before">
            <table mat-table [dataSource]="element.participants" class="participant-table"
              *ngIf="element?.participants?.length > 0">
              <ng-container matColumnDef="participantIcon">
                <td mat-cell *matCellDef="let element">
                  <div [ngSwitch]="element.participantType" class="participant-list-icon-container">
                    <img *ngSwitchCase="'SYSTEM'" class="icon-container" src="assets/images/systemActiveIcon.png"
                      matTooltip="{{'Common.System' | translate}}">
                    <img *ngSwitchCase="'USER'" class="icon-container" src="assets/images/activeParticipant.png"
                      matTooltip="{{'Common.User' | translate}}">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="displayName">
                <td mat-cell *matCellDef="let element">
                    {{element.displayName}}
                </td>
              </ng-container>
              <ng-container matColumnDef="entity">
                <td mat-cell *matCellDef="let element">
                    {{element.entityName}}
                </td>
              </ng-container>
              <tr mat-row *matRowDef="let row; columns: shareParticipantsColumns;">
              </tr>
            </table>
            <ng-template #NoRecordsFound>
              <div class="NoResultFound searchedParticipantsTable">
                  <mat-label style="padding-left:2vh;">{{'Common.NoResultFound_txt' | translate}}</mat-label>
              </div>
            </ng-template>
            <div class="buttonContainer">
              <button class="participants_button" *ngIf="element.participants?.length>10"
                (click)="openShareWithParticipantTab(element)">{{'MyShare.Full_list_of_participants' | translate}}</button>
            </div>
          </mat-menu>
        </td>
      </ng-container>

      <!-- Size Column -->
      <ng-container matColumnDef="shareSizeMB">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'MyShare.Size'| translate}}</th>
        <td mat-cell appThrottleClick *matCellDef="let element" class="pointer" (throttleClick)="openShare(element)">
          <span>{{formatFileSize(element.shareSizeMB) | customFileSize}}</span>
        </td>
      </ng-container>

      <!-- Options Column -->
      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <span class="optionIconContainer">
            <img src="assets/images/options.png" mat-icon-button [matMenuTriggerFor]="menu"
            matTooltip="{{'Images.Options' | translate}}" alt="{{'Images.Options'|translate}}">
          </span>
          <mat-menu #menu="matMenu" xPosition="before">
            <div class="optionWrapper">
              <div class="optionMenu" (click)="downloadAllFiles(element)" [ngClass]="disableDownloadButton(element) ? 'disableClass': ''">
                <img src="assets/images/downloadAllFiles.png" class="icons">
                <span>{{'MyShare.Download_files'| translate}}</span>
              </div>
              <div [ngClass]="isCurrentUserNotAnOwnerOfAShare(element) ? 'not-allowed-icon' : ''">
                <div class="optionMenu" [ngClass]="isCurrentUserNotAnOwnerOfAShare(element) ? 'disableIcon' : ''" (click)="openShareWithSettingsTab(element)">
                  <img src="assets/images/renameIcon.png" class="icons">
                  <span>{{'MyShare.Rename'| translate}}</span>
                </div>
              </div>
              <div [ngClass]="isCurrentUserNotAnOwnerOfAShare(element) ? 'not-allowed-icon' : ''">
                <div class="optionMenu" [ngClass]="isCurrentUserNotAnOwnerOfAShare(element) ? 'disableIcon' : ''" (click)="openDialog(element.id)">
                  <img src="assets/images/trash.png" class="icons">
                  <span>{{'MyShare.DeleteShare'| translate}}</span>
                </div>
              </div>
            </div>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </section>
  <div class="loading" *ngIf="isLoadingData">{{'MyShare.Loading_txt' | translate}}</div>
</main>