import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, map } from 'rxjs';
import { AddPersonDialogComponent } from '../dialogs/add-person/add-person.component';
import { HttpApiRequestService } from '../http/http-api-request.service';
import { ContactOperation, IExternalContact } from '../types/participant.model';
import { UserLoginService } from './login.service';

@Injectable()
export class PersonDataService {

  public personData: IExternalContact;
  public personForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<AddPersonDialogComponent>,
    private _httpApiRequestService: HttpApiRequestService,
    private _loginService: UserLoginService,
    private formBuilder: FormBuilder) {

  }

  initialiseValidators() {
    this.personForm = this.formBuilder.group({
      name: [
        this.personData.name,
        {
          validators: [Validators.required]
        }
      ],
      emailAddress: [
        this.personData.emailAddress,
        {
          validators: [Validators.required, Validators.email],
          asyncValidators: [this.uniqueExternalEmailValidator()],
          updateOn: 'blur'
        }
      ],
      languageCode: [
        this.personData.languageCode,
        {
          validators: [Validators.required]
        }
      ]
    });
  }

  initialiseData(data: IExternalContact) {
    this.personData = data;
  }

  getPersonData(): IExternalContact {
    let data = this.personData;
    return data;
  }

  savePersonDetails(): Subject<any> {
    let obs$ = new Subject<any>;
    if (this.personData.operation == ContactOperation.edit) {
      this._httpApiRequestService.updateExternalContact(this.personData).subscribe({
        next: (result) => {
          obs$.next(result);
        },
        error: (error) => obs$.error(error),
        complete: () => { }
    })
    } else if (this.personData.operation == ContactOperation.Add) {
        this._httpApiRequestService.inviteExternalContact(this.personData, this._loginService.entityId).subscribe({
          next: (result) => {
            obs$.next(result);
          },
          error: (error) => obs$.error(error),
          complete: () => { }
        })
    } else {
      obs$.next(false)
    }
    return obs$;
  }

  private uniqueExternalEmailValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return this._httpApiRequestService.searchExternalContacts(0, control.value, this._loginService.entityId).pipe(
        map(res => {
          if (res.length > 0) {
            control.setErrors({emailExist: true})
            return {emailExist: true}
          }
          return null;
        })
      )
    }
  }
}
