import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DialogModel } from 'src/app/types/dialog-model';
import { HttpApiRequestService } from 'src/app/http/http-api-request.service';
import { UserLoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-removed-from-share-dialog',
  templateUrl: './removed-from-share-dialog.component.html',
  styleUrls: ['./removed-from-share-dialog.component.scss']
})
export class RemovedFromShareDialogComponent {

  constructor(public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogModel,
    private _loginService: UserLoginService,
    private _httpApiRequestService: HttpApiRequestService) { }

  refreshShares() {
    if (this._loginService.isLoggedInUserIsAdmin()) {
      this._httpApiRequestService.refreshAllShares(this._loginService.entityId);
    }
    this._httpApiRequestService.refreshMyShares();
    this.dialog.closeAll();
  }
}
