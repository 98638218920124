import { Component } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { BaseAbstractComponent } from '../base-abstract/base-abstract.component';
import { UserLoginService } from '../services/login.service';
import { LandingPage, NavigationManagerService } from '../services/navigation-manager.service';
import { UserRole } from '../types/user-roles.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends BaseAbstractComponent {

  activeButton: boolean = true;
  tab: number = 0;
  displayFullOuterContainer: boolean; // change css of outer border of sidebar container
  loggedInUserRole: UserRole = UserRole.NONE;

  constructor(private _navigationManager: NavigationManagerService,
    private _loginService: UserLoginService) {
    super();
    this.setupSubscription();
  }

  public selectedTabChange(e: MatTabChangeEvent) {
    this.tab = e.index;
  }

  public mySharesButtonClick() {
    this.activeButton = true;
    this._navigationManager.navigateToPage(LandingPage.MyShares);
  }

  public allSharesButtonClick() {
    this.activeButton = true;
    this._navigationManager.navigateToPage(LandingPage.AllShares);
  }

  public contactButtonClick() {
    this.activeButton = false;
    this._navigationManager.navigateToPage(LandingPage.Contacts);
  }

  private setupSubscription() {
    const adminRoleChanged = this._loginService.onAdminRoleChanged({
      next: (value) => {
        this.loggedInUserRole = value;
        if (value === UserRole.ADMIN) {
          this.displayFullOuterContainer = true;
        } else if (value === UserRole.ADMIN_AS_USER || value === UserRole.USER) {
          this.tab = 0;
          this.activeButton = true;
          this.displayFullOuterContainer = false;
        } else if (value === UserRole.GUESTUSER) {
          this.tab = 0;
          this.activeButton = true;
          this.displayFullOuterContainer = true;
        }
      },
      error: (error) => { this.handleError(error) },
      complete() { },
    });
    this.subscriptions.push(adminRoleChanged);
  }

  // Show contacts button with My shares button
  public showContactsButton() {
    return !this._loginService.isLoggedInUserIsAdmin();
  }

  public getUserRole(value: string): boolean {
    return value === this.loggedInUserRole;
  }
}
