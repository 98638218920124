import { HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { TokenExchangeService } from '../../services/token-exchange.service';
import { IParticipant } from '../../types/participant.model';
import { AbstractRequest, requestType } from './requestUrl.model';

export class LoadMySharesRequest extends AbstractRequest {

    constructor(public tokenExchangeService: TokenExchangeService, public pageNumber: number, public shareName: string) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Get;
        const params = {'shareName': this.shareName, 'page': this.pageNumber, 'maxPageSize': 25, 'sort': 'creationDateTime', 'sortDirection': 'DESC', 'externalPersonToken': this.tokenExchangeService.getInviteTokenParam()};
        this.options = {
            params: params,
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat('/shares');
    }
}

export class LoadAllSharesRequest extends AbstractRequest {

    constructor(public tokenExchangeService: TokenExchangeService, public pageNumber: number, public shareName: string, public entityId: string) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Get;
        const params = {'shareName': this.shareName, 'page': this.pageNumber, 'maxPageSize': 25, 'sort': 'creationDateTime', 'sortDirection': 'DESC'};
        this.options = {
            params: params,
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat('/shares/entity/', this.entityId);
    }
}

export class GetShareRequest extends AbstractRequest {

    constructor(private _shareid: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Get;
        this.options = {
            params: {'externalPersonToken': this.tokenExchangeService.getInviteTokenParam()},
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat('/shares/', this._shareid);
    }
}

export class UpdateShareRequest extends AbstractRequest {

    constructor(private _data: Object, private _shareid: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Put;
        this.body = this._data;
        this.options = {
          headers: this.headers,
          responseType: 'json'
        }
        this.url = this.url.concat('/shares/', this._shareid);
    }
}

export class DeleteShareRequest extends AbstractRequest {

    constructor(private _shareid: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Delete;
        this.options = {
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat('/shares/', this._shareid);
    }
}

export class DownloadAllFilesShareRequest extends AbstractRequest {

    constructor(private _shareid: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Get;
        this.options = {
            params: {'externalPersonToken': this.tokenExchangeService.getInviteTokenParam()},
            headers: this.headers,
            responseType: 'blob'
        }
        this.url = this.url.concat('/shares/', this._shareid, '/files-zip');
    }
}

export class UploadFilesRequest extends AbstractRequest {
    constructor(private _file: File, private _shareid: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Put;
        this.body = new FormData();
            this.body.set('file', this._file);
            this.options = {
                headers: this.headers,
                responseType: 'file'
            }
            this.url = this.url.concat('/shares/', this._shareid, '/files');
    }
}

export class LoadFilesRequest extends AbstractRequest {
    constructor(private _shareid: string, private filter:string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Get;
        this.options = {
            params: {'searchBarInput': this.filter, 'externalPersonToken': this.tokenExchangeService.getInviteTokenParam()},
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat('/shares/', this._shareid, '/files');
    }
}

export class LoadFileRequest extends AbstractRequest {
    constructor(private _shareid: string, private _fileId: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Get;
        this.options = {
            params: {'externalPersonToken': this.tokenExchangeService.getInviteTokenParam()},
            headers: this.headers,
            responseType: 'blob'
        }
        this.url = this.url.concat('/shares/', this._shareid, '/files/', this._fileId);
    }
}

export class DeleteFileRequest extends AbstractRequest {

    constructor(private _shareid: string, private _id: string, public tokenExchangeService: TokenExchangeService,
                ) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Delete;
        this.options = {
            headers: this.headers,
            responseType: 'text'
        }
        this.url = this.url.concat('/shares/', this._shareid, '/files/', this._id);
    }
}

export class CreateShareRequest extends AbstractRequest {

    constructor(private _data: Object, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
         this.type = requestType.Post;
         this.body = this._data;
         this.options = {
             headers: this.headers,
             responseType: 'json'
         }
         this.url = this.url.concat('/shares');
    }
}

export class UpdateShareWithParticipantsRequest extends AbstractRequest {
    constructor(private _shareid: string, private _participantid: string, private _entityName:string, private _entityId:string, private _additionalContact:boolean, private _workRelationId: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Post;
        this.options = {
            headers: this.headers,
            responseType: 'json'
        }
        this.body = {
            "UUID": this._participantid,
            "role": "READER",
            "entityName": this._entityName,
            "entityId": this._entityId,
            "additionalContact": this._additionalContact,
            "workRelationId": this._workRelationId
          }
        this.url = this.url.concat('/rights/', this._shareid, '/participants');
    }
}

export class SearchFileExtensionRequest extends AbstractRequest {
    constructor(private _searchfield: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Get;
        this.options = {
            params: { 'extension': this._searchfield },
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat('/file-extensions');
    }
}

export class LoadParticipantRequest extends AbstractRequest {
    constructor(private _shareId: string, private filter: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Get;
        this.options = {
            params: {'searchBarInput': this.filter},
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat('/rights/', this._shareId, '/participants');
    }
    override processResponse(result: Observable<any>): Subject<any> {
        let obs$ = new Subject<IParticipant[]>;
        result.subscribe({
            next: (result: IParticipant[]) => {
                obs$.next(result);
            },
            error: (error) => { obs$.error(error); },
            complete: () => { }
        })
        return obs$;
    }
}

export class UpdateRoleParticipantsRequest extends AbstractRequest {
    constructor(private _shareid: string, private _participantid: string, private _role: string, private _workRelationId: string,  public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Put;
        this.options = {
            headers: this.headers,
            responseType: 'json'
        }
        this.body = {
            "UUID": this._participantid,
            "role": this._role,
            "workRelationId": this._workRelationId
            }
        this.url = this.url.concat('/rights/', this._shareid, '/participants/', this._participantid);

    }
}

export class DeleteParticipantFromShareRequest extends AbstractRequest {
    constructor(private _shareid: string, private _participantid: string, private _workRelationId: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Delete;
        this.options = {
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat('/rights/', this._shareid, '/participants/', this._participantid, '/workRelationId/', this._workRelationId);
    }
}

export class LoadActionLogsRequest extends AbstractRequest {
    constructor(private _shareid: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Get;
        this.options = {
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat('/actions/', this._shareid);
    }
}

export class DownloadActionLogsRequest extends AbstractRequest {
    constructor(private _shareid: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Get;
        this.options = {
            headers: this.headers,
            responseType: 'blob'
        }
        this.url = this.url.concat('/actions/', this._shareid, '/download');
    }
}

export class UploadChunkRequest extends AbstractRequest {
    constructor(public chunkData:any, public shareId: string, public fileId:string, public tokenExchangeService: TokenExchangeService, private chunkNumber: number, private chunkSize: number, private fileName: string) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Put;
        this.body = new FormData();
        this.body.set('file', this.chunkData)
        this.options = {
            headers: this.headers,
        }
        const params = new HttpParams()
        .set('chunkNumber', this.chunkNumber)
        .set('chunkSize', this.chunkSize)
        .set('fileName', this.fileName + '_' + this.chunkNumber + '_' + this.fileId);
        this.url = this.url.concat('/shares/' + this.shareId + '/chunked-file/' + this.fileId+'?' + params);
    }
}

export class FileIdRequest extends AbstractRequest {
    constructor(private _shareId: string, public tokenExchangeService: TokenExchangeService, private fileName:string, private fileSize: number, private fileType: string) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Post;
        this.options = {
            headers: this.headers,
            responseType: 'json'
        }
        const params = new HttpParams()
        .set('fileName', this.fileName)
        .set('finalSize', this.fileSize)
        .set('fileMimeType', this.fileType);
        this.url = this.url.concat('/shares/' + this._shareId + '/chunked-file?' + params.toString());
    }
}

export class ChunkedFileUploadFinishRequest extends AbstractRequest {
    constructor(private _shareId: string, private fileId: string, private fileName: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Put;
        this.options = {
            headers: this.headers
        }
        const params = new HttpParams().set('fileName', this.fileName);
        this.url = this.url.concat('/shares/' + this._shareId + '/chunked-file/' + this.fileId + '/finished?' + params.toString());
    }
}

export class GetUploadedChunksRequest extends AbstractRequest {
    constructor(private _shareId: string, private fileId: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Get;
        this.options = {
            headers: this.headers
        }
        this.url = this.url.concat('/shares/'+ this._shareId + '/chunked-file/'+this.fileId)
    }
}

export class FilterActionLogsRequest extends AbstractRequest {
    constructor(private _shareId: string, private filter: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Get;
        this.options = {
            headers: this.headers
        }
        const params = new HttpParams()
        .set('searchBarInput', this.filter)
        this.url = this.url.concat('/actions/', this._shareId, '?', params.toString());
    }
}

export class GetSystemRightsRequest extends AbstractRequest {
    constructor(private _participantId: string, public tokenExchangeService: TokenExchangeService){
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Get;
        this.options = {
            headers: this.headers
        }
        const params = new HttpParams()
        .set('participantId', this._participantId)
        this.url = this.url.concat('/proxy/rights/?', params.toString());
    }
}

export class SendAddExternalContactLogRequest extends AbstractRequest {
    constructor(private _shareId: string, private _displayName: string, private _externalContactId: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Post;
        this.options = {
            headers: this.headers
        }
        this.body = {'displayName' : this._displayName, 'externalContactId': this._externalContactId}
        this.url = this.url.concat('/rights/' + this._shareId + '/externals');
    }
}