<div class="title-container tab">
    <div class="searchContainer">
        <input #search type="text" name="searchKey" [(ngModel)]="searchKey"
            placeholder="{{'Contacts.Search_bar_external' | translate}}" autocomplete="off" maxlength="50" />
    </div>
</div>
<div>
    <section class="table-container mat-elevation-z8" infinite-scroll
        [scrollWindow]="false" (scrolled)="onScrollDown($event)">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="icon" class="iconColumn">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" (click)="openContact(element)">
                    <img class="icon-container" src="assets/images/activeParticipant.png"
                        matTooltip="{{'Common.User' | translate}}">
                </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Contacts.Table.Column_name' | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="pointer" (click)="openContact(element)">
                    <div textOverflow [textValue]="element.name">{{element.name}}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="emailAddress">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Contacts.Table.Column_email' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="pointer" (click)="openContact(element)">
                    <div textOverflow [textValue]="element.emailAddress">
                        {{element.emailAddress}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="entityId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Common.Organization' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="pointer" (click)="openContact(element)">
                    <div textOverflow [textValue]="element.entityId">
                        {{element.entityId}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="languageCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Common.Language' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="pointer" (click)="openContact(element)">
                    <div textOverflow [textValue]="element.languageCode">
                        {{element.languageCode}}
                    </div>
                </td>

            </ng-container>
            <ng-container matColumnDef="options">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <img class="icon-container" src="assets/images/options.png" mat-icon-button
                        [matMenuTriggerFor]="menu" *ngIf="enableOptions(element)"
                        matTooltip="{{'Images.Options' | translate}}" alt="{{'Images.Options'|translate}}">
                    <mat-menu #menu="matMenu" xPosition="before">
                        <div class="optionWrapper">
                            <div class="optionMenu" (click)="openContact(element)">
                                <img src="assets/images/renameIcon.png" class="icons">
                            <span>{{'MyShare.Edit'| translate}}</span>
                            </div>
                            <div class="optionMenu" (click)="openDeleteDialog(element)">
                                <img src="assets/images/trash.png" alt="{{'Images.DeleteFiles' | translate}}"
                                    class="icons">
                                <span>{{'MyShare.DeleteShare'| translate}}</span>
                            </div>
                        </div>
                    </mat-menu>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="tableRows"></tr>
        </table>
    </section>
    <div class="loading" *ngIf="isLoadingData">{{'MyShare.Loading_txt' | translate}}</div>
</div>