import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime, fromEvent, map } from 'rxjs';
import { BaseAbstractComponent } from 'src/app/base-abstract/base-abstract.component';
import { FilterOption } from 'src/app/common-ui/filter/model/filter-options.model';
import { AddPersonDialogComponent } from 'src/app/dialogs/add-person/add-person.component';
import { DeleteExternalDialogComponent } from 'src/app/dialogs/delete-external-dialog/delete-external-dialog.component';
import { HttpApiRequestService } from 'src/app/http/http-api-request.service';
import { UserLoginService } from 'src/app/services/login.service';
import { RightsDialogModel } from 'src/app/types/dialog-model';
import { ContactOperation, IContact, IExternalContact } from 'src/app/types/participant.model';
import { CompareUtility } from 'src/app/utilities/compare.utility';


@Component({
  selector: 'app-external-contact-tab',
  templateUrl: './external-contact-tab.component.html',
  styleUrls: ['./external-contact-tab.component.scss']
})
export class ExternalContactTabComponent extends BaseAbstractComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('search') filterElementRef: ElementRef;

  dataSource = new MatTableDataSource<IExternalContact>([]);
  statusFilterOptions: FilterOption[] = [];
  isLoadingData: boolean = false;
  pageNumber: number = 1;
  searchKey: string = '';
  displayedColumns = ['icon', 'name', 'emailAddress', 'entityId', 'languageCode', 'options'];

  constructor(private _loginService: UserLoginService,
    private _httpApiService: HttpApiRequestService) {
    super();
    this.setupSubscription();
  }
  
  ngOnInit() {
    this._httpApiService.refreshExternals(this._loginService.entityId);
  } 

  ngAfterViewInit() {
    const keyup$ = fromEvent(this.filterElementRef.nativeElement, 'keyup');
    keyup$
      .pipe(
        map((i: any) => i.currentTarget.value),
        debounceTime(500)
      )
      .subscribe({
        next: (result: any) => {
          this.pageNumber = 0;
          this._httpApiService.searchExternalContacts(this.pageNumber, result.trim(), this._loginService.entityId).subscribe({
            next: (result: IExternalContact[]) => {
              this.dataSource.data = result;
              this.pageNumber = 1;
            },
            error: (error) => {
              this.handleError(error);
            },
            complete: () => { }
          });
        },
        error: (error) => { this.handleError(error); },
        complete: () => { }
      }
      );
  }

  openContact(data: IExternalContact) {
    if (this._loginService.isLoggedInUserIsAdmin()) {
      let external = new IExternalContact();
      external = data;
      external.operation = ContactOperation.edit;
      const dialogRef = this.dialog.open(AddPersonDialogComponent, {
        panelClass: 'dialog-container',
        height: '400px',
        width: '450px',
        data: external
      });
      dialogRef.afterClosed().subscribe((result: IExternalContact)=> {        
          this._httpApiService.refreshExternals(this._loginService.entityId);
      });
    }
  }

  openDeleteExternalDialog(external: IExternalContact, shareNames: string[]): void {
    let data = new RightsDialogModel();
    this.translate.get('Dialogs.DeleteExternalDialog.Title').subscribe((x: string) => data.title = x);
    this.translate.get('Dialogs.DeleteExternalDialog.Message').subscribe((x: string) => data.content = x);
    data.shares = shareNames.filter(x => x !== null);
    let heightDialogBox: string = shareNames.length > 0 ? '285px' : '255px';
    const dialogRef = this.dialog.open(DeleteExternalDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: data,
      height: heightDialogBox,
      width: '450px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.deleteExternal(external);
      }
    });
  }

  openDeleteDialog(external: IExternalContact): void {
    this._httpApiService.getExternalRights(external.externalContactId).subscribe({
      next: (result) => {
        this.openDeleteExternalDialog(external, result);
      }
    })
  }

  public selectAll() {
    this.dataSource.filter = 'all';
  }

  public enableOptions(value: IContact): boolean {
    return this._loginService.isLoggedInUserIsAdmin();
  }

  public deleteExternal(external: IExternalContact) {
    this._httpApiService.deleteExternal(external.externalContactId).subscribe({
      next: (result) => {
        this.removeExternal(external.externalContactId);
        this.refreshTableData(this.dataSource.data);
      },
      error: (error) => {
        this.handleError(error);
      },
      complete: () => { }
    });
  }
  
  public onScrollDown(event: any) {
    this.isLoadingData = true;
    this._httpApiService.loadExternals(this.pageNumber, this.searchKey.trim(), this._loginService.entityId).subscribe({
      next: (result: IExternalContact[]) => {
        this.appendRecords(result);
        this.refreshTableData(this.dataSource.data);
        setTimeout(() => {this.isLoadingData = false;}, 500);
      },
      error: (error) => { this.handleError(error); },
      complete: () => { }
    });
  }
  
  public isLoggedInUserIsAdmin() {
    return this._loginService.isLoggedInUserIsAdmin();
  }

  private refreshTableData(result: IExternalContact[]) {
    this.dataSource.data = result;
  }

  private removeExternal(id: string) {
    const index = this.dataSource.data.findIndex(x => x.externalContactId === id);
    if (index > -1) { // only splice array when item is found
      this.dataSource.data.splice(index, 1); // 2nd parameter means remove one item only
    }
  }

  private appendRecords(result: IExternalContact[]) {
    if (CompareUtility.isDefinedAndNotNull(result) && result.length > 0) {
      this.dataSource.data = this.dataSource.data.concat(result);
      this.pageNumber = this.pageNumber + 1;
    }
  }

  private setupSubscription() {
    //TODO refresh externals
    const refreshExternalsSubscription = this._httpApiService.onExternalsRefreshed({
      next: (result) => {
        this.dataSource.data = result;
        this.searchKey = '';
        this.pageNumber = 1;
        this.refreshTableData(this.dataSource.data);
        this.dataSource.sort = this.sort;
      },
      error: (error) => { this.handleError(error); },
      complete() { },
    })
    this.subscriptions.push(refreshExternalsSubscription);
  }

}
