import { TokenExchangeService } from '../../services/token-exchange.service';
import { ExternalsAbstractRequest, requestType } from './requestUrl.model';
import { IExternalContact } from '../../types/participant.model';

export class SearchExternalContactsRequest extends ExternalsAbstractRequest {
    constructor(private _searchfield: string, private _pageNumber: number, public tokenExchangeService: TokenExchangeService, private entityId: string) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Get;
        this.options = {
            params: {
                'name': this._searchfield,
                'emailAddress': this._searchfield,
                'page': this._pageNumber,
                'maxPageSize': 20,
                'sort': 'name',
                'sortDirection': 'ASC',
                'entityId': this.entityId
            },
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat('/external-contact');
    }
}

export class InviteExternalContactsRequest extends ExternalsAbstractRequest {
    constructor(private externalContact: IExternalContact, public tokenExchangeService: TokenExchangeService, private entityId: string) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Post;
        this.options = {
            headers: this.headers,
            responseType: 'json'
        }
        this.body = {
            'name': this.externalContact.name,
            'emailAddress': this.externalContact.emailAddress,
            'languageCode': this.externalContact.languageCode,
            'entityId': this.entityId
        }
        this.url = this.url.concat('/external-contact');
    }
}

export class LoadExternalContactsRequest extends ExternalsAbstractRequest {
    constructor(private _shareId: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }
    override processRequest(): void {
        this.type = requestType.Get;
        this.options = {
            headers: this.headers,
            responseType: 'json'
        }
        this.url = this.url.concat('/invite/', this._shareId);
    }
}

export class SendInviteRequest extends ExternalsAbstractRequest {
    constructor(private _shareId: string, private _participantId: string, private _ownerName: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Post;
        this.options = {
            headers: this.headers
        }
        this.body = {'shareId': this._shareId, 'externalContactId': this._participantId, 'ownerName': this._ownerName};
        this.url = this.url.concat('/invite');
    }
}

export class DeleteExternalRequest extends ExternalsAbstractRequest {
    constructor(private _externalId: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Delete;
        this.options = {
            headers: this.headers
        }
        this.url = this.url.concat('/external-contact/' + this._externalId);
    }
}

export class GetExternalRightsRequest extends ExternalsAbstractRequest {
    constructor(private _externalId: string, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Get;
        this.options = {
            headers: this.headers
        }
        this.url = this.url.concat('/invite/external-contact-id/' + this._externalId);
    }
}

export class UpdateExternalContactRequest extends ExternalsAbstractRequest {
    constructor(private _externalContact: IExternalContact, public tokenExchangeService: TokenExchangeService) {
        super(tokenExchangeService);
        this.processRequest();
    }

    override processRequest(): void {
        this.type = requestType.Put
        this.options = {
            headers: this.headers
        }
        this.body = this._externalContact;
        this.url = this.url.concat('/external-contact/' + this._externalContact.externalContactId);
    }
}